
// Компонент для отображения части атрибутов из записи справочника
import {
  ApiHelper,
  ApiService,
  ButtonComponent,
  EditableHelpComponent,
  EnterModalComponent,
  EnterModalProps,
  HelpHelper,
  KeyboardEventHelper,
  Logger,
  NotificationsService,
  PanelFiltersSet,
  TABLE_ICONS,
  TableActionsComponent,
  TableComponent,
  TableDataSet,
  TableDataSetFactory,
  TableModel,
  TableMultiHeaders,
  TablePaginationBottom,
  TableRow,
  VOC_NAMES_DICT,
} from "table";
import { EditingVocDataSet } from "@/modules/editing-voc/classes/EditingVocDataSet";
import { defineComponent } from "vue";
import {
  EditingVocDataSetData,
  VocExtraInfo,
} from "@/modules/editing-voc/common/types";
import { TableEmitUpdateCell } from "table/dist/components/TableComponent/common/types/TableEmitUpdateCell";
import { APP_ICONS, HEADER_IDS, VOC_CLASSES } from "@/common/consts";
import { TableRowData } from "table/dist/types/TableRowRecord";
import SwitchHints from "@/components/smart/SwitchHints.vue";
import ApiVocService from "@/modules/editing-voc/services/ApiVocService/ApiVocService";
import {
  VocDto,
  VocRepositoryDto,
  VocValueDto,
} from "table/dist/services/Api/types/VocRepositoryDto";
import EditingVocModal, {
  EditingVocModalMode,
} from "@/modules/editing-voc/components/EditingVocModal.vue";
import EditingVocExtendedModal from "@/modules/editing-voc/components/EditingVocExtendedModal.vue";
import TableVocRepository from "@/modules/editing-voc/classes/TableVocRepository";
import { TableCellPointer } from "table/dist/components/TableComponent/common/types/TableCellPointer";
import AppApiService from "@/services/AppApiService/AppApiService";
import { ApiPaginationParams } from "table/dist/services/Api/types/ApiPaginationParams";
import { PaginationMode } from "table/dist/types/LazyPaginationState";
import { useSaveSettingsInTableDataSet } from "@/services/UserSettingsService/helpers";
import { PanelFiltersSaveModule } from "@/classes/PanelFiltersSaveModule";
import { applyPanelFiltersData } from "@/common/helpers/applyPanelFiltersData";
import HeaderSaveIndicator from "@/components/HeaderSaveIndicator.vue";
import EditingVocEntry from "@/modules/editing-voc/components/EditingVocEntry.vue";

export default defineComponent({
  name: "EditingVocEntries",
  components: {
    SwitchHints,
    EditingVocModal, // используется через <component :is
    EditingVocExtendedModal, // используется через <component :is
    EditingVocEntry,
    TableComponent,
    TableMultiHeaders,
    ButtonComponent,
    TablePaginationBottom,
    EditableHelpComponent,
    EnterModalComponent,
    TableActionsComponent,
    HeaderSaveIndicator,
  },
  setup() {
    return {
      APP_ICONS,
      VOC_CLASSES,
      VOC_NAMES_DICT,
      TABLE_ICONS,
      HEADER_IDS,
    };
  },
  data() {
    return {
      vocList: null as VocDto[] | null,
      vocRepositoryDto: null as null | VocRepositoryDto,
      vocExtraInfo: null as null | VocExtraInfo,
      values: null as null | VocValueDto[],
      table: {
        tableDataSet: null as null | TableDataSet,
        factory: null as null | TableDataSetFactory,
        edit: false,
        editingVocDataSet: null as null | EditingVocDataSet,
      },
      open: {
        vocEntryModal: null as {
          record_id?: number;
          mode: EditingVocModalMode;
        } | null,
        enterModal: null as null | EnterModalProps,
      },
      helpHelper: new HelpHelper(),
    };
  },
  created() {
    this.$watch(
      () => this.params.voc_type,
      async () => {
        await this.initVocType();
        this.table.edit = false;
        this.initEdit();
      },
    );
    this.$watch(
      () => this.table.edit,
      () => {
        this.initEdit();
      },
    );
  },
  async mounted() {
    await this.initVocType();
  },
  deactivated() {
    this.table.tableDataSet?.destroy();
    this.table.editingVocDataSet?.destroy();
  },
  computed: {
    emptyHelp(): string | undefined {
      if (this.vocExtraInfo?.edit.hint) {
        return "[не задано]";
      }

      return undefined;
    },

    currentTableModelHelp(): TableModel | undefined {
      const context = this.helpHelper.getContext();
      if (!context) {
        return undefined;
      }

      return context.tableDataSet.getModel(context.field);
    },

    currentHelpValue(): string | undefined {
      const model = this.currentTableModelHelp;
      if (!model) {
        return undefined;
      }
      if (model.modelDto.help) {
        return model.modelDto.help;
      }

      return this.emptyHelp;
    },

    params(): { voc_type: string } {
      return this.$route.params as any;
    },

    vocDto(): VocDto | undefined {
      return this.vocList?.find(
        (x: VocDto) => x.voc_type === this.params.voc_type,
      );
    },
  },
  methods: {
    initEdit() {
      const edit = this.table.edit && this.vocRepositoryDto!.edit.edit;
      const hint = this.vocExtraInfo!.edit.hint;
      this.table.tableDataSet!.setEditMode({
        edit,
        hint,
      });
    },

    async initVocType() {
      if (!this.params.voc_type) {
        return;
      }

      this.vocList = null;
      this.vocRepositoryDto = null;
      this.vocExtraInfo = null;
      this.table.tableDataSet = null;
      this.table.factory = null;
      this.values = null;
      try {
        this.vocList = (await AppApiService.getVocsTypesList()).json.result;
        this.vocRepositoryDto = (
          await ApiService.getVocRepository(this.params.voc_type)
        ).json;
        this.vocExtraInfo = await AppApiService.getVocExtraInfo(
          this.params.voc_type,
        );
        await this.initEditingVocDataSet(this.vocRepositoryDto);
      } catch (e) {
        Logger.error({ e });
        const errorText = await ApiHelper.getErrorMessage(e);
        NotificationsService.send({
          type: "error",
          title: "Произошла ошибка при загрузке",
          text: errorText,
        });
      }
    },

    setHelp(pointer: Required<TableCellPointer> | null) {
      const { tableDataSet } = this.table;
      if (!pointer || !tableDataSet) {
        this.helpHelper.setHelp(null);
        return;
      }

      const model = tableDataSet.getModel(pointer.col_name);
      if (!model) {
        return;
      }

      this.helpHelper.setHelp(model.modelDto.help || this.emptyHelp || null, {
        tableDataSet: this.table.tableDataSet,
        field: pointer.col_name,
      });
    },

    onOpenHelpEdit() {
      const context = this.helpHelper.getContext();
      if (!context) {
        return;
      }

      const model = this.currentTableModelHelp!;
      const title = `Редактирование подсказки для столбца "${model.caption}"`;
      this.open.enterModal = {
        title,
        modelValue: model.modelDto.help || "",
        onUpdateModelValue: async (value: string) => {
          if (await this.onUpdateHint(model, value)) {
            this.onCloseEnterModal();
          }
        },
        onClose: this.onCloseEnterModal,
      };
    },

    onCloseEnterModal() {
      this.open.enterModal = null;
    },

    async onUpdateHint(model: TableModel, text: string) {
      if (!this.params.voc_type) {
        return;
      }

      try {
        await ApiVocService.changeHint(
          {
            voc_type: this.params.voc_type,
            attr: model.field,
          },
          { text },
        );
        model.modelDto.help = text;
        this.helpHelper.setHelp(null);
      } catch (ex: any) {
        await ApiHelper.wrapNotifyError(ex, { isError: true });
        return false;
      }

      return true;
    },

    getEditingVocDataSetData(
      paginationParams?: ApiPaginationParams,
      editingVocDataSetData?: EditingVocDataSetData,
    ) {
      return EditingVocDataSet.getData(
        this.params.voc_type,
        paginationParams,
        editingVocDataSetData,
      );
    },

    onRouteVocByPointer(pointer: TableCellPointer) {
      const tableRow = this.table.tableDataSet?.getRow(pointer?.row);
      if (!tableRow) {
        return;
      }

      const vocValueDto: VocValueDto = tableRow.original;
      this.open.vocEntryModal = {
        record_id: vocValueDto.record_id,
        mode: "edit",
      };
    },

    onCellKeyDown({
      event,
      pointer,
    }: {
      event: KeyboardEvent;
      pointer: Required<TableCellPointer>;
    }) {
      if (this.table.edit) {
        if (this.table.tableDataSet!.currentEdit !== null) {
          const vocValueDto: VocValueDto = this.table.tableDataSet!.getRow(
            pointer.row,
          )!.original;
          if (!vocValueDto.edit?.edit) {
            this.table.tableDataSet?.setCurrentEdit(null);
          }
        }
        return;
      }

      const eventHelper = new KeyboardEventHelper(event);
      if (eventHelper.keyEnter.isAllTrue) {
        this.onRouteVocByPointer(pointer);
        return;
      }
    },

    onCellDblClick({
      event,
      pointer,
    }: {
      event: MouseEvent;
      pointer: Required<TableCellPointer>;
    }) {
      if (this.table.edit) {
        return;
      }

      this.onRouteVocByPointer(pointer);
    },

    onUpdateCells(updates: TableEmitUpdateCell[]) {
      const updatesFiltered = updates.filter((update) => {
        const vocValueDto: VocValueDto = update.original;
        if (vocValueDto && !vocValueDto.edit?.edit) {
          NotificationsService.send({
            type: "warn",
            title: "Внимание",
            text: `Редактирование записи "${vocValueDto.label}" запрещено`,
          });
          return false;
        }

        return true;
      });
      if (updatesFiltered.length) {
        this.table.tableDataSet!.updateCells(updatesFiltered);
      }
    },

    onCellsClear(pointers: Required<TableCellPointer>[]) {
      const pointersFiltered = pointers.filter((pointer) => {
        const vocValueDto: VocValueDto = this.table.tableDataSet!.getRow(
          pointer.row,
        )!.original;
        if (vocValueDto && !vocValueDto.edit?.edit) {
          NotificationsService.send({
            type: "warn",
            title: "Внимание",
            text: `Редактирование записи "${vocValueDto.label}" запрещено`,
          });
          return false;
        }

        return true;
      });

      if (pointersFiltered.length) {
        this.table.tableDataSet!.cellsClear(pointersFiltered);
      }
    },

    onRowDelete(pointers: Required<TableCellPointer>[]) {
      const pointersFiltered = pointers.filter((pointer) => {
        const vocValueDto: VocValueDto = this.table.tableDataSet!.getRow(
          pointer.row,
        )!.original;
        if (vocValueDto && !vocValueDto.edit?.edit) {
          NotificationsService.send({
            type: "warn",
            title: "Внимание",
            text: `Удаление записи "${vocValueDto.label}" запрещено`,
          });
          return false;
        }

        return true;
      });
      if (pointersFiltered.length) {
        this.table.tableDataSet!.rowDelete(pointersFiltered);
      }
    },

    addVocEntry(vocValueDto: VocValueDto) {
      const tableDataSet = this.table.tableDataSet!;
      const row = tableDataSet.rows.length;
      const model = tableDataSet.model as TableModel[];
      const tableRow = TableRow.getTableRowObj(
        {
          row,
          original: vocValueDto,
          data: Object.keys(vocValueDto.attrs || {}).reduce((value, field) => {
            value[field] = { value };
            return value;
          }, {} as TableRowData),
        },
        model,
      );
      tableDataSet.rows.push(tableRow);
    },

    async onPagination(mode: PaginationMode) {
      const { tableDataSet, editingVocDataSet, factory } = this.table;
      if (!editingVocDataSet || !tableDataSet || !factory) {
        throw new Error("data not found");
      }

      await editingVocDataSet.paginationLoad(
        mode,
        tableDataSet as any,
        factory as TableDataSetFactory,
      );
    },

    async initEditingVocDataSet(repository: VocRepositoryDto) {
      this.table.editingVocDataSet = null;
      this.table.factory = null;
      this.table.tableDataSet = null;

      const editingVocDataSet = await this.getEditingVocDataSet(repository);
      const data = await editingVocDataSet.get();
      const factory = await this.getTableDataSetFactory(data, repository);
      const tableDataSet = await factory.create();
      await useSaveSettingsInTableDataSet(tableDataSet);
      await editingVocDataSet.updateValuesRemain(tableDataSet);

      this.table.editingVocDataSet = editingVocDataSet;
      this.table.factory = factory;
      this.table.tableDataSet = tableDataSet as any;
      this.initEdit();
    },

    getEditingVocDataSet(repository: VocRepositoryDto) {
      return new EditingVocDataSet({
        voc_type: this.params.voc_type,
        lazy_load_size: repository.lazy_load_size,
      });
    },

    async getTableDataSetFactory(
      data: EditingVocDataSetData,
      repository: VocRepositoryDto,
    ) {
      const uniqId = this.params.voc_type;
      const filtersSaveModule = new PanelFiltersSaveModule(`voc_${uniqId}`);
      await filtersSaveModule.init();
      const filtersSet = new PanelFiltersSet(filtersSaveModule);
      filtersSet.subject.subscribe((data) =>
        applyPanelFiltersData(this.table.tableDataSet as TableDataSet, data),
      );
      return new TableDataSetFactory({
        tableName: "voc",
        uniqId,
        headers: repository.form.table.headers,
        modelDtoArray: repository.form.table.model,
        rows: data.values.map((x) => x.attrs),
        rowsOriginal: data.values,
        types: repository.types,
        repository: new TableVocRepository(),
        getSystemField: (model) => `attrs.${model.field}`,
        tableDataSetOptions: {
          listeners: {
            destroy: {
              next: (tableDataSet) => {
                this.table.editingVocDataSet?.tableDataSetDestroy(tableDataSet);
              },
            },
            changeFilters: {
              next: async () => {
                if (!this.table.editingVocDataSet || !this.table.factory) {
                  throw new Error("not found data");
                }

                await this.table.editingVocDataSet.onChangeFilters(
                  this.table.tableDataSet as any,
                  this.table.factory as TableDataSetFactory,
                );
              },
            },
            changeSort: {
              next: async () => {
                if (!this.table.editingVocDataSet || !this.table.factory) {
                  throw new Error("not found data");
                }

                await this.table.editingVocDataSet.onChangeSort(
                  this.table.tableDataSet as any,
                  this.table.factory as TableDataSetFactory,
                );
              },
            },
          },
          extra: {
            voc_type: this.params.voc_type,
          },
          helpHelper: this.helpHelper,
          searchDebounce: 300,
          filtersSet,
        },
      });
    },
  },
});
