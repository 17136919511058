import {
  ApiService,
  FilterFactory,
  isNumeric,
  PaginationHelper,
  SortFactory,
  TableDataSet,
  TableDataSetFactory,
  TablePagination,
} from "table";
import { EditingVocDataSetData } from "@/modules/editing-voc/common/types";
import { ApiPaginationParams } from "table/dist/services/Api/types/ApiPaginationParams";
import { PaginationMode } from "table/dist/types/LazyPaginationState";
import { ApiListLazyWrapper } from "table/dist/services/Api/types/ApiListWrapper";
import { ApiSortField } from "table/dist/services/Api/types/ApiSortField";
import { FilterLogicalObj } from "table/dist/services/Api/types/ApiFilter";
import { ApiListParams } from "table/dist/services/Api/types/ApiListParams";


interface EditingVocDataSetParams {
  lazy_load_size?: number;
  uniqueId?: string;
  voc_type: string;
}

export class EditingVocDataSet {
  constructor(params: EditingVocDataSetParams) {
    this.voc_type = params.voc_type;
    this.lazy_load_size = params.lazy_load_size;
    if (params.uniqueId) {
      this.uniqueId = params.uniqueId;
    }

    this.initPagination();
  }

  voc_type: string;
  lazy_load_size?: number;
  uniqueId = Math.random().toString();
  pagination!: TablePagination;
  data: EditingVocDataSetData | undefined = undefined;

  async initRows(
    dataSetData: EditingVocDataSetData,
    tableDataSet: TableDataSet,
    factory: TableDataSetFactory,
  ) {
    const tableRows = factory
      .setRows(
        dataSetData.values.map((x) => x.attrs),
        dataSetData.values,
      )
      .getTableRows(tableDataSet.model);

    tableDataSet.initRows(tableRows);
    this.updateValuesRemain(tableDataSet);
  }

  clearCache(): this {
    this.initPagination();
    ApiService.clearCache(this.getKey());
    this.data = undefined;
    return this;
  }

  // если ключ поменялся - очищаем кэш для предыдущего ключа
  checkCache(prevKey: string, newKey: string) {
    if (prevKey === newKey) {
      return;
    }

    ApiService.clearCache(prevKey);
  }

  async get(
    paginationParams = this.pagination.params,
  ): Promise<EditingVocDataSetData> {
    this.pagination.setLoading(true);
    const data = await ApiService.useCache(this.getKey(paginationParams), () =>
      this.getEditingVocDataSetData(paginationParams),
    );
    this.setPaginationParams(paginationParams);
    this.pagination.setLoading(false);
    this.data = data;
    return data;
  }

  async paginationLoad(
    mode: PaginationMode,
    tableDataSet: TableDataSet,
    factory: TableDataSetFactory,
  ) {
    const paginationParams = PaginationHelper.getPaginationByMode(
      mode,
      this.pagination,
    );
    if (!paginationParams) {
      return;
    }

    const dataSetData = await this.get(paginationParams);
    await this.initRows(dataSetData, tableDataSet, factory);
  }

  setPaginationParams(pagination: ApiPaginationParams) {
    const prevKey = this.getKey(this.pagination.params);
    this.pagination.setParams(pagination);
    const newKey = this.getKey(pagination);
    this.checkCache(prevKey, newKey);
  }

  setFilter(filter: FilterLogicalObj | undefined) {
    this.setPaginationParamsPartial({
      filter: FilterFactory.getString(filter),
    });
  }

  setSort(sort: ApiSortField[] | undefined) {
    this.setPaginationParamsPartial({ sort: SortFactory.getString(sort) });
  }

  setPaginationParamsPartial(paginationParams: Partial<ApiPaginationParams>) {
    this.setPaginationParams({
      ...this.pagination.params,
      ...paginationParams,
    });
  }

  updateValuesRemain(tableDataSet: TableDataSet) {
    // если стейт пагинации не указан, то пагинация выключена
    if (!this.data?.lazyPaginationState) {
      return;
    }

    const pagination = this.pagination;
    pagination.setValuesRemain(this.data.lazyPaginationState.values_remain);
    tableDataSet.options.pagination = pagination;
  }

  async onChangeFilters(
    tableDataSet: TableDataSet,
    factory: TableDataSetFactory,
  ) {
    if (!tableDataSet.isServerManagement) {
      await this.setFilter(undefined);
      return;
    }

    this.pagination.setOffset(0).normaliseLimit();
    const filter = tableDataSet.getFilterLogicalObj();
    this.setFilter(filter);
    await this.onLoad(tableDataSet, factory);
  }

  async onChangeSort(tableDataSet: TableDataSet, factory: TableDataSetFactory) {
    if (!this) {
      throw new Error("data not found");
    }

    if (!tableDataSet.isServerManagement) {
      await this.setSort(undefined);
      return;
    }

    this.pagination.setOffset(0).normaliseLimit();
    const sort = tableDataSet.getSort();
    this.setSort(sort);
    await this.onLoad(tableDataSet, factory);
  }

  async onLoad(tableDataSet: TableDataSet, factory: TableDataSetFactory) {
    const dataSetData = await this.get();
    await this.initRows(dataSetData, tableDataSet, factory);
  }

  destroy() {
    this.clearCache();
  }

  tableDataSetDestroy(tableDataSet: TableDataSet) {
    this.pagination.clear();
    this.initPagination();
  }

  private getKey(pagination = this.pagination.params) {
    return `voc-data-set-${this.voc_type}_${
      this.uniqueId
    }_${TablePagination.getKey(pagination)}`;
  }

  private initPagination() {
    if (!this.pagination) {
      const lazyLoadSize = isNumeric(this.lazy_load_size)
        ? this.lazy_load_size
        : undefined;
      this.pagination = new TablePagination({
        lazyLoadSize,
      });
    }

    this.pagination.setOffset(0);
  }

  private getEditingVocDataSetData(
    paginationParams = this.pagination.params,
  ): Promise<EditingVocDataSetData> {
    return EditingVocDataSet.getData(
      this.voc_type,
      paginationParams,
      this.data,
    );
  }

  static async getData(
    voc_type: string,
    listParams?: ApiListParams,
    editingVocDataSetData?: EditingVocDataSetData,
  ): Promise<EditingVocDataSetData> {
    const { json: valuesResult } = await ApiService.getVocValues<any>(
      voc_type,
      listParams,
      false,
      undefined,
      undefined,
    );
    let values = valuesResult.result;
    if (editingVocDataSetData) {
      if (listParams?.offset) {
        values = [...editingVocDataSetData.values, ...values];
      }
    }

    const lazyPaginationState = listParams
      ? PaginationHelper.getStateByLazyResult(
          valuesResult as ApiListLazyWrapper<any>,
        )
      : undefined;

    return {
      values,
      newValues: valuesResult.result,
      lazyPaginationState,
    };
  }
}
